<div class="column">
    <mat-card appearance="outlined" class="profile-component avatar-component" *ngIf="org && org.type === 'employer'">
        <shared-avatar-update
        [img]="org.avatar"
        id="button_change_user_avatar"
        [load]="avatarUpload$ | async"
        (file)="onSubmitAvatar($event)"></shared-avatar-update>
    </mat-card>
    
    <mat-card appearance="outlined">
        <form [formGroup]="form">

            <div class="highlight-notice alert" *ngIf="!!genericError">
                <div class="material-icons-outlined">
                    warning
                </div>
                <div class="notice">
                    {{ genericError | translate }}
                </div>
            </div>

            <mat-form-field *ngIf="org"
            [class.form-field-highlight]="form.get('country').value === 'SE'">
                <mat-label>{{'Organization Form' | translate}}</mat-label>
                <mat-select id="org_form" [placeholder]="'Organization Form' | translate"
                            data-testid="org_form"
                            formControlName="form" required>
                    <mat-select-trigger>
                        <div class="select-trigger-element">
                            <mat-icon class="select-icon" *ngIf="form.controls.form.value" matPrefix fontSet="material-icons-outlined">
                                {{ getSelectedFormIcon(form.controls.form.value) }}
                            </mat-icon>
                            <span class="select-text">
                                {{ getSelectedFormValue(form.controls.form.value) | translate }}
                            </span>
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let org_form of formList"
                                [attr.data-testid]="'org_form_' + org_form.key"
                                [value]="org_form.key"
                                [disabled]="isOrgFormDisabled(org_form.onlyForCountries)">
                        <mat-icon fontSet="material-icons-outlined" matPrefix>{{org_form.icon}}</mat-icon>
                        {{org_form.value | translate}}
                    </mat-option>
                </mat-select>
                <mat-error>{{form.get('form').errors?.serverError}}</mat-error>
            </mat-form-field>
        
            <br>
            <div class="highlight-notice alert" *ngIf="form.get('country').value === 'SE' && form.get('form').value === 'Private' && !form.get('personal_number').value">
                <div class="material-icons-outlined">
                    warning
                </div>
                <div class="notice">
                    {{ 'If you choose this option, you must demonstrate your right to work within Sweden. At this point you can:' | translate }}
                    <ul>
                        <li translate>Continue without it. Manual validation will be required.</li>
                        <li translate>Register as Business. This means that you're in charge of your taxes and a valid VAT number will be required.</li>
                        <li translate>Validate personal number now.</li>
                    </ul>
                </div>
            </div>
            <ng-container *ngIf="form.get('form').value === 'Private'">
                <ng-container *ngIf="org.id === currentOrg">
                    <shared-ssn-form
                    style="display: block;"
                    [validatableEntity]="'org'"></shared-ssn-form>
                </ng-container>
    
                <ng-container *ngIf="org.id !== currentOrg">
                    <mat-form-field>
                        <mat-label>
                            {{ 'personal number' | translate | titlecase }}
                        </mat-label>
                        <input id="org_personal_number" 
                        data-testid="org_personal_number"
                        type="text"
                        matInput
                        formControlName="personal_number"
                        placeholder="XXXXXXXXXX">
                        <mat-error>{{form.get('personal_number').errors?.serverError}}</mat-error>
                    </mat-form-field>
                </ng-container>
            </ng-container>
    
            <mat-form-field *ngIf="form.get('form').value === 'Business'">
                <mat-label>
                    {{ 'vat number' | translate | titlecase }}
                </mat-label>
                <input id="org_vat_number" 
                data-testid="org_vat_number"
                type="text"
                matInput
                formControlName="vat_number"
                placeholder="SE550000000001">
                <mat-icon matSuffix
                    [matTooltip]="'Enter your VAT number. For example, SE550000000001 for Sweden. Ensure the number is accurate to ensure proper tax processing.' | translate"
                    aria-label="Displays a tooltip when focused or hovered over">
                    help_outline</mat-icon>
                <mat-error>{{form.get('vat_number').errors?.serverError}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'currency' | translate | titlecase }}</mat-label>
                <mat-select
                id="org_currency"
                data-testid="org_currency"
                formControlName="currency"
                >
                    <mat-option 
                        *ngFor="let currency of currencies"
                        [attr.data-testid]="'org_currency_' + currency.value"
                        [value]="currency.value">
                        {{'(' + currency.value + ') ' + currency.viewValue}}
                    </mat-option>
                </mat-select>
                <mat-error>{{form.get('currency').errors?.serverError}}</mat-error>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>
                    <span *ngIf="form.get('form').value === 'Private'">
                        {{'First and last name' | translate | titlecase}}
                    </span>
                    <span *ngIf="form.get('form').value === 'Business'">
                        {{'Company name' | translate | titlecase}}
                    </span>
                </mat-label>
                <input
                id="org_name"
                data-testid="org_name"
                type="text"
                matInput
                formControlName="name"
                placeholder="Company AB">
                <mat-error>{{form.get('name').errors?.serverError}}</mat-error>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>{{'email' | translate | titlecase}}</mat-label>
                <input 
                id="org_email" 
                data-testid="org_email"
                type="email"
                matInput
                formControlName="email"
                placeholder="me@company.com">
                <mat-error>{{form.get('email').errors?.serverError}}</mat-error>
            </mat-form-field>
    
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'country' | translate | titlecase }}</mat-label>
                    <mat-select
                    (valueChange)="onCountryChange($event)"
                    id="org_country" 
                    data-testid="org_country"
                    formControlName="country">
                        <mat-option 
                            *ngFor="let country of countries" 
                            [id]="'org_country_' + country.value?.value"
                            [value]="country.value?.value">
                                {{country.value?.name | translate}}
                        </mat-option>
                    </mat-select>
    
                    <button 
                    data-testid="org_button_getgeolocation"
                    mat-icon-button
                    type="button"
                    matSuffix 
                    (click)="getGeoLocation($event)">
                        <span class="material-icons-outlined">
                            my_location
                        </span>
                    </button>
    
                    <mat-error>{{form.get('country').errors?.serverError}}</mat-error>
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label>{{ 'city' | translate | titlecase }}</mat-label>
                    <input 
                    id="org_city" 
                    data-testid="org_city"
                    type="text"
                    matInput
                    formControlName="city">
                    <mat-error>{{form.get('city').errors?.serverError}}</mat-error>
                </mat-form-field>
            </div>
    
            <mat-form-field>
                <mat-label>{{'address' | translate | titlecase}}</mat-label>
                <input 
                id="org_address1" 
                data-testid="org_address1"
                type="text" matInput formControlName="address1">
                <mat-error>{{form.get('address1').errors?.serverError}}</mat-error>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>{{'address' | translate | titlecase}}</mat-label>
                <input 
                id="org_address2" 
                data-testid="org_address2"
                type="text" matInput formControlName="address2">
                <mat-error>{{form.get('address2').errors?.serverError}}</mat-error>
            </mat-form-field>   
    
            <mat-form-field>
                <mat-label translate>Postal code</mat-label>
                <input 
                id="org_postcode" 
                data-testid="org_postcode"
                type="text"
                matInput
                formControlName="postcode"
                placeholder="12345">
                <mat-error>{{form.get('postcode').errors?.serverError}}</mat-error>
            </mat-form-field>
        </form>
    </mat-card>
</div>