import { Injectable } from '@angular/core'
import { User } from '../../models/user.model'
import { interval, Observable, map, share, switchMap, takeWhile, tap, of, repeat } from 'rxjs'
import { ApiService } from '../api/api.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ShoutlyEidSubmitSMSOtpErrorResponse, ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { OrganizationsService } from '../organizations/organizations.service'
import { StoreService } from '../store/store.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor (
    private api: ApiService,
    private snackBar: MatSnackBar,
    private organizationsService: OrganizationsService,
    private storeService: StoreService
  ) {}

  public getUser () {
    this.api.getUserProfile()
      .subscribe({
        next: (data: User) => this.storeService.cacheUserData(data)
      })
  }

  public getUserObservable (): Observable<User> {
    return this.api.getUserProfile()
      .pipe(
        tap((data: User) => this.storeService.cacheUserData(data))
      )
  }

  public updateUserData (updateUser: Partial<User>): Observable<User> {
    const previousUser = this.storeService.getCachedUser()
    console.log('updateUser: ', updateUser)

    if (previousUser.id === -1) {
      return this.updateLoggedOutUserObservable({ ...previousUser, ...updateUser })
    }
    
    return this.updateUserObservable(updateUser)
  }

  private updateLoggedOutUserObservable (data: User): Observable<User> {
    return of(data)
      .pipe(
        tap(data => this.storeService.cacheUserData(data))
      )
  }

  private updateUserObservable (data: Partial<User>): Observable<User> {
    data = this.purgeUserValues(data)

    return this.api.updateUserProfile(data)
      .pipe(
        tap(data => this.storeService.cacheUserData(data)),
        tap(() => this.snackBar.open(_('User updated'), null, { panelClass: ['shoutly-snack-bar', 'success'], duration: 2000 }))
      )
  }

  public sendVerificationEmail (email: string) {
    return this.api.sendVerificationEmail(email)
  }

  public changeCurrentOrgFlag (org_id) {
    return this.api.changeCurrentOrgFlag(org_id)
      .pipe(
        switchMap(() => this.organizationsService.getExtendedOrganizationData(this.getUserObservable())),
      )
  }

  private purgeUserValues (user) {
    delete user.avatar
    return user
  }

  /** Validators */

  private eidSSNStartTransaction (): Observable<ShoutlyEidTransactionResponse> {
    return this.api.eidUserSSNStartTransaction()
  }

  /* Make a long poll request and stop if status is not started */
  private eidSSNCheckTransaction(transaction_id: string, personal_number: string): Observable<ShoutlyEidTransactionResponse> {
    const timer$ = interval(2000);
  
    return this.api.eidUserSSNCheckTransaction(transaction_id, personal_number).pipe(
      repeat({ delay: () => timer$ }),
      takeWhile(tr => (tr.status === 'started'), true)
    )
  }

  public eidSSNStartAndCheckTransaction (personal_number: string): Observable<ShoutlyEidTransactionResponse> {
    return this.eidSSNStartTransaction()
      .pipe(
        switchMap(transaction => this.eidSSNCheckTransaction(transaction.id, personal_number)),
        share()
      )
  }

  public eidMobileStartTransaction (mobile: string): Observable<ShoutlyEidTransactionResponse> {
    return this.api.eidMobileStartTransaction(mobile)
  }

  public eidMobileCheckTransaction (transaction_id: string, code: string): Observable<ShoutlyEidSubmitSMSOtpErrorResponse | ShoutlyEidTransactionResponse> {

    return this.api.eidMobileCheckTransaction(transaction_id, code)
      .pipe(
        /** handle code invalid as error */
        map(data => {
          if ((data as ShoutlyEidSubmitSMSOtpErrorResponse)?.oneTimeCodeValid === false) {
            throw data
          }
          return data
        }),
        tap((tr: ShoutlyEidTransactionResponse) => {
          if (tr.status === 'complete') this.getUser()
        })
      )
  }

  public setUserGuideSeen (): Observable<User> {
    return this.api.updateUserGuideTour({ guide_seen: 1 })
      .pipe(
        tap(data => this.storeService.cacheUserData(data))
      )
  }
}
