import { BankidDialogComponent } from '../../dialogs/bankid-dialog/bankid-dialog.component'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { Subject, Observable, tap, takeUntil, take, startWith } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { HelpersService } from '../../services/helpers/helpers.service'
import { User } from '../../models/user.model'
import { ShoutlyEidTransactionResponse } from '../../auth/models/auth.model'
import { UserService } from '../../services/user/user.service'
import { StoreService } from '../../services/store/store.service'
import { OrganizationsService } from '../../services/organizations/organizations.service'
import { Organization } from '../../models/organization.model'

@Component({
  selector: 'shared-ssn-form',
  templateUrl: './ssn-form.component.html',
  styleUrls: ['./ssn-form.component.scss']
})
export class SsnFormComponent implements OnInit, OnDestroy {
  @Input() validatableEntity: 'user' | 'org' = 'user'
  @Output() valueChange = new EventEmitter<FormGroup>()

  constructor(
    private helpersService: HelpersService,
    private userService: UserService,
    private orgService: OrganizationsService,
    private dialog: MatDialog,
    private storeService: StoreService
  ) { }

  public sendOTPButtonConfig = this.helpersService.getButtonConfig(_('Validate'))
  private entity$: Observable<User | Organization>
  public entity: User | Organization
  private destroy$: Subject<void> = new Subject()
  public get isButtonDisabled () {
    const ssnField = this.form.get('personal_number')
    return ssnField.invalid || ssnField.pristine || ssnField.value === '' || ssnField.value === this.entity?.personal_number
  }

  ngOnInit(): void {
    if (this.validatableEntity === 'user') {
      this.entity$ = this.storeService.user$
        .pipe(
          takeUntil(this.destroy$),
          tap(user => this.helpersService.patchValues(this.form, user)),
          tap(() => this.form.get('personal_number').markAsPristine())
        )
    }
    if (this.validatableEntity === 'org') {
      this.entity$ = this.storeService.organization$
        .pipe(
          takeUntil(this.destroy$),
          tap(org => this.helpersService.patchValues(this.form, org)),
          tap(() => this.form.get('personal_number').markAsPristine())
        )
    }

    this.entity$
      .pipe()
      .subscribe({
        next: (entity) => {
          this.entity = entity
        }
      })

    this.form.valueChanges
      .pipe(
        startWith(this.form),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.valueChange.emit(this.form))
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public form = new FormGroup({
    personal_number: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)])
  })

  // open dialog with qr code to be authorized that closes when authorized
  private openDialog(transaction) {
    return this.dialog.open(BankidDialogComponent, {
      data: {
        eidTransaction: transaction
      }
    })
  }

  private getUser() {
    return this.userService.getUser()
  }

  private getOrg() {
    return this.orgService.getOrganization()
      .subscribe()
  }

  public validate() {
    const personal_number = this.form.get('personal_number').value
    const dialogRef = this.openDialog(personal_number)

    this.sendOTPButtonConfig.isLoading = true
    this.sendOTPButtonConfig.isDisabled = true

    const service = this.validatableEntity === 'user' ? this.userService : this.orgService
    service.eidSSNStartAndCheckTransaction(personal_number.toString())
      .pipe(
        takeUntil(dialogRef.afterClosed())
      )
      .subscribe({
        next: (response: ShoutlyEidTransactionResponse) => {
          dialogRef.componentInstance.data.eidTransaction = response
        },
        error: (err) => {
          dialogRef.close()
          this.form.get('personal_number').setErrors({ serverError: err.error?.message })
          this.sendOTPButtonConfig.isLoading = false
          this.sendOTPButtonConfig.isDisabled = false
        },
        complete: () => {
          dialogRef.close()
          this.validatableEntity === 'user' ? this.getUser() : this.getOrg()
          this.sendOTPButtonConfig.isLoading = false
          this.sendOTPButtonConfig.isDisabled = false
        }
      })
  }
}
