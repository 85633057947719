import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MobileFormComponent } from '../components/mobile-form/mobile-form.component'
import { SsnFormComponent } from './ssn-form/ssn-form.component'
import { OrgFormComponent } from './org-form/org-form.component'
import { EmailFormComponent } from './email-form/email-form.component'
import { UserNameFormComponent } from './user-name-form/user-name-form.component'
import { BillingFormComponent } from './billing-form/billing-form.component'
import { BankSettingsFormComponent } from './bank-settings-form/bank-settings-form.component'
import { PaymentsFormComponent } from './payments-form/payments-form.component'
import { SwishFormComponent } from './swish-form/swish-form.component'
import { PaypalFormComponent } from './paypal-form/paypal-form.component'
import { CreateBonusFormComponent } from './create-bonus-form/create-bonus-form.component'
import { ReactiveFormsModule, FormsModule as AngularFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { MaterialModule } from '../material.module'
import { MobilePhoneInputComponent } from './mobile-phone-input/mobile-phone-input.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { UiModule } from '../ui/ui.module'
import { AvatarUpdateComponent } from '../components/avatar-update/avatar-update.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { UserAvatarWrapperComponent } from '../components/user-avatar-wrapper/user-avatar-wrapper.component'
import { AuthProviderSelectComponent } from './auth-provider-select/auth-provider-select.component'
import { QrCodeModule } from 'ng-qrcode'
import { NgOtpInputModule } from 'ng-otp-input'
import { RecaptchaV3Module } from 'ng-recaptcha-2'
import { RouterModule } from '@angular/router'
import { NgPipesModule } from 'ngx-pipes'
import { FileUploadComponent } from '../components/file-upload/file-upload.component'
import { DialogFiltersForTablesComponent } from '../components/dialog-filters-for-tables/dialog-filters-for-tables.component'
import { CookieConsentComponent } from '../components/cookie-consent/cookie-consent.component'
import { LanguageSelectorComponent } from '../components/language-selector/language-selector.component'
import { UserCabinetComponent } from './user-cabinet/user-cabinet.component'
import { BankidQrComponent } from './bankid-qr/bankid-qr.component'
import { OtpComponent } from './otp/otp.component'
import { EmailComponent } from './email/email.component'
import { OrgFormV2Component } from './org-form-v2/org-form-v2.component'
import { FormSelectComponent } from './form-select/form-select.component'

@NgModule({
  declarations: [
    MobileFormComponent,
    MobilePhoneInputComponent,
    SsnFormComponent,
    OrgFormComponent,
    OrgFormV2Component,
    EmailFormComponent,
    UserNameFormComponent,
    BillingFormComponent,
    BankSettingsFormComponent,
    PaymentsFormComponent,
    SwishFormComponent,
    PaypalFormComponent,
    CreateBonusFormComponent,
    AvatarUpdateComponent,
    UserAvatarWrapperComponent,
    BankidQrComponent,
    OtpComponent,
    EmailComponent,
    AuthProviderSelectComponent,
    FileUploadComponent,
    DialogFiltersForTablesComponent,
    CookieConsentComponent,
    LanguageSelectorComponent,
    UserCabinetComponent,
    FormSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AngularFormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    QrCodeModule,
    NgOtpInputModule,
    UiModule,
    TranslateModule.forChild(),
    InlineSVGModule,
    RecaptchaV3Module,
    RouterModule,
    NgPipesModule
  ],
  exports: [
    AngularFormsModule,
    ReactiveFormsModule,
    MobileFormComponent,
    SsnFormComponent,
    OrgFormComponent,
    OrgFormV2Component,
    EmailFormComponent,
    UserNameFormComponent,
    BillingFormComponent,
    BankSettingsFormComponent,
    PaymentsFormComponent,
    SwishFormComponent,
    PaypalFormComponent,
    CreateBonusFormComponent,
    BankidQrComponent,
    OtpComponent,
    EmailComponent,
    AuthProviderSelectComponent,
    CookieConsentComponent,
    LanguageSelectorComponent,
    AvatarUpdateComponent,
    UserAvatarWrapperComponent,
    FileUploadComponent,
    UserCabinetComponent,
    FormSelectComponent
  ],
})
export class FormsModule { }
