import { ShoutlyGenericStatusResponse } from './status.model'
import { ShoutlyTimelineElement } from './timeline.model'

export enum WalletType {
  UpfrontCost = 'upfront_cost',
  PostpaidCost = 'postpaid_cost',
  Upfront = 'upfront',
  Postpaid = 'postpaid',
  ExpenseCost = 'expense_cost',
  Pension = 'pension',
  Discrepancy = 'discrepancy',
  CurrentFee = 'current_fee'
}

export interface MinimalWalletItem {
  id: number
  type: WalletType
  collab_id: number
  title: string
  amount: number
  netto: number
  employer_id?: number
  employer_name?: string
}

export interface WalletResponse extends MinimalWalletItem {
  org_id: number
  currency: string
  paid_at: string
  cancelled: number
  related_to: number
  invoice_id: number
  selfinvoice_id: number
  created_at: string
  updated_at: string
  period: string
  payout_date: string
  cost_center: { id: number, name: string }
  paid: boolean
  has_bill: boolean
  timeline: ShoutlyTimelineElement[]
  vat: string
  gigger?: {id, name, avatar}
  employer?: {id, name, avatar}
  has_selfinvoice: boolean
  is_gigger_transaction: boolean
  from_balance: any
  status: string
  related_status: string
  generic_status: string
}

export interface Wallet extends WalletResponse {}

export interface Balance extends WalletResponseV2{
  balance_after: number
}

// also called bill
export interface Invoice {
  id: number
  org_id: number
  amount: number
  currency: string
  external_id: number
  exported_at: string
  paid_at: string
  created_at: string
  updated_at: string
  due_date: string
  file: string
  name: string
  status: ShoutlyGenericStatusResponse
  transactions?: WalletResponseV2[]
  timeline?: ShoutlyTimelineElement[]
}

export interface SelfInvoice {
  id: number
  org_id: number
  is_company: number
  name: string
  vat_number: string
  email: string
  address1: string
  address2: string | null
  postcode: string
  city: string
  country: string
  state: string | null
  reference: any | null
  payment_file: number
  payout_method: string
  currency: string
  fee: number
  currency_fee: string
  fx_owner_id: any | null
  external_state: number
  payment_state: 'paid' | 'not-paid' | 'cancelled'
  subtotal: string
  vat_amount: string
  round_off: string
  payroll_fees: string
  total: string
  external_id: number | null
  exported_at: string | null
  paid_at: string
  status: ShoutlyGenericStatusResponse
  created_at: string
  updated_at: string
  account_holder: string
  transferwise_status: string | null
  file?: string
  eta: string | null
  timeline?: ShoutlyTimelineElement[]
  wise_payout: WisePayoutSelfInvoiceItem | null
  swish_payout: SwishPayoutSelfInvoiceItem | null
  paypal_payout: PayPalPayoutSelfInvoiceItem | null
  wallet: MinimalWalletItem[]
}

export interface SelfInvoiceV2 {
  id: number
  org_id: number
  is_company: number
  name: string
  vat_number: string
  email: string
  address1: string
  address2: string | null
  postcode: string
  city: string
  country: string
  state: string | null
  reference: any | null
  payment_file: number
  payout_method: string
  currency: string
  fee: number
  currency_fee: string
  fx_owner_id: any | null
  external_state: number
  payment_state: 'paid' | 'not-paid' | 'cancelled'
  subtotal: string
  vat_amount: string
  round_off: string
  payroll_fees: string
  total: string
  external_id: number | null
  exported_at: string | null
  paid_at: string
  status: ShoutlyGenericStatusResponse
  created_at: string
  updated_at: string
  account_holder: string
  transferwise_status: string | null
  file?: string
  eta: string | null
  timeline?: ShoutlyTimelineElement[]
  wise_payout: WisePayoutSelfInvoiceItem | null
  swish_payout: SwishPayoutSelfInvoiceItem | null
  paypal_payout: PayPalPayoutSelfInvoiceItem | null
  wallet: MinimalWalletItem[]
}

export interface SwishPayoutSelfInvoiceItem {
  // Define fields based on actual data structure
}

export interface PayPalPayoutSelfInvoiceItem {
  // Define fields based on actual data structure
}

export interface WisePayoutSelfInvoiceItem {
  selfinvoice_id: number
  address1: string
  city: string
  postcode: string
  bank_country: string
  state: string | null
  iban: string
  account: string
  clearing: string | null
  bic: string
  uk_sort_code: string | null
  us_routing_number: string | null
  bank_code: string | null
  institution_number: string | null
  transit_number: string | null
  clabe: string
  id_number: string
  ifsc: string | null
  transfer_type: string
  account_type: 'CHECKING' | 'SAVINGS'
  legal_type: 'PRIVATE' | 'BUSINESS'
  transferwise_id: number | null
  transferwise_status: string | null
}

export interface ApiRequestBillCreate{
  transactions: Array<number>
}

export interface ApiResponseBillCreate{
  transaction_id: number
  invoice_id: number
}

export interface WalletRequestPageParams{
  page: number
  limit: 1 | 10 | 20 | 50 | 100 | number
  from?: string
  to?: string
  org_id?: string[]
  cost_center_id?: string[]
  sort?: 'asc' | 'desc'
  orderby?: 'id' | 'org_id' | 'collab_id' | 'type' | 'amount' | 'netto' | 'currency' | 'paid_at' | 'cancelled'
}

export interface WalletResponseV2 {
  amount: number
  balance_after?: number | null
  billed_with?: string | null
  cancelled: number | boolean
  collab_id: number
  cost_center: { id: number, name: string }
  created_at: string
  currency: string
  employer?: { id: number, name: string, avatar: string }
  from_balance?: number | null
  fx_owner_id?: number | null
  generic_status?: string
  gigger?: { id: number, name: string, avatar: string }
  has_bill?: boolean
  has_selfinvoice: boolean
  id: number
  invoice_id?: number | null
  is_gigger_transaction: boolean
  netto: number
  org_id: number
  paid: boolean
  paid_at: string | null
  period?: string | null
  payout_date?: string | null
  related_status?: string
  related_to: number | null
  selfinvoice_id?: number | null
  status: ShoutlyGenericStatusResponse
  timeline: ShoutlyTimelineElement[]
  title: string
  type: WalletType
  updated_at: string
  vat: string
}
