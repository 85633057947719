export enum ShoutlyGenericStatusResponseType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info'
}

export interface ShoutlyGenericStatusResponse {
  slug: string
  type: ShoutlyGenericStatusResponseType
  caption: string
  label: string
}